@font-face {
  font-family: Montserrat-Light;
  src: url(../assets/fonts/Montserrat-Light.ttf) format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../assets/fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../assets/fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

//Colors
$black_90: rgba(0, 0, 0, 0.9);
$white: rgb(255, 255, 255);
$color_oslo_gray_approx: #8c8f94;
$color_shark_approx: #26272d;
$color_mine_shaft_approx: #333;
$color_curious_blue_approx: rgb(30, 139, 70);
$color_black_haze_approx: #f5f8f9;
$color_international_orange_approx: rgb(30, 139, 70);
$color_grenadier_approx: rgb(30, 139, 70);
$color_scarpa_flow_approx: #57595d;
$color_gray_nurse_approx: #e9e9ea;
$black_30: rgba(0, 0, 0, 0.3);
$color_chicago_46_approx: rgba(91, 89, 89, 0.46);
$color_tapa_approx: #777;
$color_tuatara_30_approx: rgba(27, 59, 124, 0.3);
$color_ghost_approx: #cbcbce;
$color_bright_gray_approx: #3a3d4b;
$color_trinidad_approx: rgb(30, 139, 70);
$color_desert_storm_approx: #f8f8f8;
$color_zeus_approx: #231f20;
$gray: #808080;
$color_oxford_blue_approx: #3a4656;
$white_20: rgba(255, 255, 255, 0.2);
$color_orange_approx: rgb(30, 139, 70);
$color_bon_jour_approx: #e5e1e1;
$color_storm_dust_approx: #666464;
$color_baltic_sea_approx: #282c2f;
$black_70: rgba(0, 0, 0, 0.7);
$wild_sand: #f4f4f4;
$color_mid_gray_28_approx: rgba(92, 95, 101, 0.28);
$color_cod_gray_approx: #0a0a0a;
$color_gallery_approx: #efeeee;
$color_silver_sand_approx: rgb(194, 194, 194);
$black_15: rgba(0, 0, 0, 0.15);
$color_cod_gray_46_approx: rgba(12, 12, 12, 0.46);

//urls
$url_11: url(../assets/images/iphone-top.png);
$url_12: url(../assets/images/iphone-bottom.png);
$url_13: url(../assets/images/iphone-center.png);
$url_14: url(../assets/images/slide-arrow-right.png);
$url_15: url(../assets/images/slide-arrow-left.png);
$url_16: url(../assets/images/svg/check-icon.svg);

h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
li,
p,
blockquote {
  font-family: Montserrat-Regular;
}
//@extend-elements
//original selectors
//html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, hr, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figure, figcaption, hgroup, menu, footer, header, nav, section, summary, time, mark, audio, video
%extend_1 {
  margin: 0;
  padding: 0;
  border: 0;
}

//original selectors
//h1, h2, h3, h4, h5, h6
%extend_2 {
  font-weight: 700;
  line-height: normal;
  color: $color_shark_approx;
}

//original selectors
//h2, h6
%extend_3 {
  font-size: 42px;
  line-height: 46px;
  font-weight: 500;
}

//original selectors
//.btn.bg-dark-su:hover, .btn.bg-dark-su:active
%extend_4 {
  background-color: $color_scarpa_flow_approx !important;
  border-color: $color_scarpa_flow_approx;
  color: $white;
}

//original selectors
//.btn-transparent-grey:hover, .btn-transparent-grey:active
%extend_5 {
  border-color: $color_shark_approx;
  background-color: $color_shark_approx;
  color: $white;
}

//original selectors
//button.btn[type="submit"], input.btn[type="submit"]
%extend_6 {
  border: 0;
  font-size: 14px;
  line-height: 23px;
  min-width: 150px;
}

//original selectors
//.nav-bar:after, .nav-bar:before
%extend_7 {
  width: 100%;
  height: 100%;
  background-color: $white;
  content: '';
  left: 0;
  position: absolute;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease;
}

//original selectors
//.iphone-mock-top, .iphone-mock-bottom
%extend_8 {
  position: relative;
  left: calc(50% - -14.5px);
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateX(-50%);
  background-position: center;
}

html {
  @extend %extend_1;
  overflow-y: auto;
  overflow-x: hidden;
}
body {
  @extend %extend_1;
  background: $white;
  overflow-x: hidden;
  overflow-y: auto;
  font: 400 18px/26px Montserrat-Regular;
  color: $color_oslo_gray_approx;
}
div {
  @extend %extend_1;
}
span {
  @extend %extend_1;
}
object {
  @extend %extend_1;
}
iframe {
  @extend %extend_1;
}
h1 {
  @extend %extend_1;
  @extend %extend_2;
  margin: 0 0 10px;
  font: 400 48px / normal Montserrat-Regular;
}
h2 {
  @extend %extend_1;
  @extend %extend_2;
  margin: 0 0 10px;
  @extend %extend_3;
  margin-bottom: 30px;
}
h3 {
  @extend %extend_1;
  @extend %extend_2;
  margin: 0 0 10px;
  font-size: 32px;
  line-height: 38px;
  &.yeseva {
    font-family: Montserrat-Regular;
  }
}
h4 {
  @extend %extend_1;
  @extend %extend_2;
  margin: 0 0 10px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}
h5 {
  @extend %extend_1;
  @extend %extend_2;
  margin: 0 0 10px;
  font-size: 18px;
  line-height: 32px;
}
h6 {
  @extend %extend_1;
  @extend %extend_2;
  margin: 0 0 10px;
  @extend %extend_3;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 1px;
  color: $color_oslo_gray_approx;
  font-family: Montserrat-Regular;
  font-weight: 700;
  text-transform: uppercase;
}
p {
  @extend %extend_1;
  margin: 0 0 10px;
}
blockquote {
  @extend %extend_1;
  quotes: none;
  &:before {
    content: '';
    content: none;
  }
  &:after {
    content: '';
    content: none;
  }
}
pre {
  @extend %extend_1;
}
a {
  @extend %extend_1;
  outline: none;
  text-decoration: none;
  display: inline-block;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease;
  img {
    border: 0;
    border-style: none;
  }
}
abbr {
  @extend %extend_1;
}
address {
  @extend %extend_1;
  font-style: normal;
}
cite {
  @extend %extend_1;
}
code {
  @extend %extend_1;
}
del {
  @extend %extend_1;
}
dfn {
  @extend %extend_1;
  font-style: normal;
}
em {
  @extend %extend_1;
  font-style: normal;
}
img {
  @extend %extend_1;
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  border-style: none;
}
ins {
  @extend %extend_1;
}
kbd {
  @extend %extend_1;
}
q {
  @extend %extend_1;
  quotes: none;
  &:before {
    content: '';
    content: none;
  }
  &:after {
    content: '';
    content: none;
  }
}
samp {
  @extend %extend_1;
}
small {
  @extend %extend_1;
}
strong {
  @extend %extend_1;
  font-weight: bold;
}
sub {
  @extend %extend_1;
}
sup {
  @extend %extend_1;
}
var {
  @extend %extend_1;
}
b {
  @extend %extend_1;
  font-weight: bold;
}
i {
  @extend %extend_1;
}
hr {
  @extend %extend_1;
}
dl {
  @extend %extend_1;
}
dt {
  @extend %extend_1;
}
dd {
  @extend %extend_1;
}
ol {
  @extend %extend_1;
}
ul {
  @extend %extend_1;
  list-style: none;
}
li {
  @extend %extend_1;
}
fieldset {
  @extend %extend_1;
}
form {
  @extend %extend_1;
}
label {
  @extend %extend_1;
  cursor: pointer;
  font-weight: 500;
  margin: 0 0 5px;
  color: $color_mine_shaft_approx;
  display: block;
}
legend {
  @extend %extend_1;
}
table {
  @extend %extend_1;
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  @extend %extend_1;
}
tbody {
  @extend %extend_1;
}
tfoot {
  @extend %extend_1;
}
thead {
  @extend %extend_1;
}
tr {
  @extend %extend_1;
}
th {
  @extend %extend_1;
}
td {
  @extend %extend_1;
}
article {
  @extend %extend_1;
  display: block;
}
aside {
  @extend %extend_1;
  display: block;
}
canvas {
  @extend %extend_1;
  display: block;
}
details {
  @extend %extend_1;
}
figure {
  @extend %extend_1;
  display: block;
  img {
    display: block;
  }
}
figcaption {
  @extend %extend_1;
  display: block;
}
hgroup {
  @extend %extend_1;
  display: block;
}
menu {
  @extend %extend_1;
}
footer {
  @extend %extend_1;
  display: block;
}
header {
  @extend %extend_1;
  display: block;
}
nav {
  @extend %extend_1;
  display: block;
}
section {
  @extend %extend_1;
  display: block;
}
summary {
  @extend %extend_1;
}
time {
  @extend %extend_1;
}
mark {
  @extend %extend_1;
}
audio {
  @extend %extend_1;
  display: block;
}
video {
  @extend %extend_1;
  display: block;
}
[type='checkbox'] {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  padding: 0;
}
[type='checkbox'] {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  padding: 0;
}
[type='search'] {
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
}
::-webkit-file-upload-button {
  font: inherit;
}
input {
  outline: none;
  &:required {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  &.btn[type='submit'] {
    @extend %extend_6;
  }
  &.btn.btn-lg[type='submit'] {
    padding: 12px 25px;
  }
}
select {
  outline: none;
}
textarea {
  outline: none;
  &.input {
    height: 150px;
  }
}
img::selection {
  background: transparent;
}
img::-moz-selection {
  background: transparent;
}
input[type='number'] {
}
input::-webkit-outer-spin-button {
}
input::-webkit-inner-spin-button {
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 0 transparent;
}
input[type='search']::-webkit-search-decoration {
  display: none;
}
input[type='search']::-webkit-search-cancel-button {
  display: none;
}
input[type='search']::-webkit-search-results-button {
  display: none;
}
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
button {
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &.btn[type='submit'] {
    @extend %extend_6;
  }
}
* {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
}
.sub-title {
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
}
input[type='submit'] {
  cursor: pointer;
}
.btn {
  font: 400 16px/20px Montserrat-Regular;
  border: 1px solid transparent;
  padding: 10px 15px;
  color: $white;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease;
  &:focus {
    outline: 0 !important;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 0 0 !important;
  }
  &:active {
    outline: 0 !important;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 0 0 !important;
  }
  &:hover {
    color: $white !important;
  }
  &.bg-dark-su {
    &:hover {
      @extend %extend_4;
    }
    &:active {
      @extend %extend_4;
    }
  }
}
.expand-bg {
  position: relative;
  z-index: 1;
  > .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }
  &.expand-half-left > .bg {
    left: 0;
    right: auto;
    width: 50%;
  }
  &.expand-half-right > .bg {
    right: 0;
    left: auto;
    width: 50%;
  }
}
.bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.border-bg {
  border: 10px solid $color_black_haze_approx;
}
.btn-radial {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50px;
}
.input-radial {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50px;
}
.btn-primary {
  background-color: $color_international_orange_approx;
  border-color: $color_international_orange_approx;
  &:hover {
    background-color: $color_grenadier_approx !important;
    border-color: $color_grenadier_approx !important;
  }
  &:active {
    background-color: $color_grenadier_approx !important;
    border-color: $color_grenadier_approx !important;
  }
}
.btn-icon {
  padding-left: 30px;
  padding-right: 20px;
  position: relative;
  text-align: left;
  img {
    position: absolute;
    right: 20px;
    top: 50%;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-50%);
  }
}
.btn-i-lg {
  width: 202px;
}
.btn-transparent {
  background-color: transparent;
}
.btn-transparent-grey {
  border-color: $color_gray_nurse_approx;
  color: $color_shark_approx;
  &:hover {
    @extend %extend_5;
  }
  &:active {
    @extend %extend_5;
  }
}
.btn-gr {
  margin-left: -6px;
  > .btn {
    margin: 0 6px;
  }
}
.learn-more-btn {
  min-width: 142px;
}
.link {
  font-weight: 500;
}
.opacity-9 {
  &:not(.expand-bg) {
    opacity: 0.9;
  }
  &.bg-overlay .bg:after {
    opacity: 0.9;
  }
}
.opacity-8 {
  &:not(.expand-bg) {
    opacity: 0.8;
  }
  &.bg-overlay .bg:after {
    opacity: 0.8;
  }
}
.opacity-7 {
  &:not(.expand-bg) {
    opacity: 0.7;
  }
  &.bg-overlay .bg:after {
    opacity: 0.7;
  }
}
.opacity-6 {
  &:not(.expand-bg) {
    opacity: 0.6;
  }
  &.bg-overlay .bg:after {
    opacity: 0.6;
  }
}
.opacity-5 {
  &:not(.expand-bg) {
    opacity: 0.5;
  }
  &.bg-overlay .bg:after {
    opacity: 0.5;
  }
}
.opacity-4 {
  &:not(.expand-bg) {
    opacity: 0.4;
  }
  &.bg-overlay .bg:after {
    opacity: 0.4;
  }
}
.opacity-3 {
  &:not(.expand-bg) {
    opacity: 0.3;
  }
  &.bg-overlay .bg:after {
    opacity: 0.3;
  }
}
.opacity-2 {
  &:not(.expand-bg) {
    opacity: 0.2;
  }
  &.bg-overlay .bg:after {
    opacity: 0.2;
  }
}
.opacity-1 {
  &:not(.expand-bg) {
    opacity: 0.1;
  }
  &.bg-overlay .bg:after {
    opacity: 0.1;
  }
}
.block {
  padding: 130px 0;
}
.block-center {
  margin: 0 auto;
  display: block;
}

.no-margin {
  margin: 0 !important;
}
.half-margin {
  margin: 50px 0;
}
.half-margin-top {
  margin-top: 50px;
}
.half-margin-bottom {
  margin-bottom: 50px;
}
.quatar-half-margin {
  margin: 30px 0;
}
.quatar-half-margin-top {
  margin-top: 30px;
}
.quatar-half-margin-bottom {
  margin-bottom: 30px;
}
.no-padding {
  padding: 0 !important;
}
.half-padding {
  padding: 50px 0;
}
.half-padding-top {
  padding-top: 50px;
}
.quatar-half-padding {
  padding: 30px 0;
}
[class*='edge-shadow-'] {
  background-color: $white;
}
.edge-shadow-one {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 25px $black_30;
}
.edge-shadow-two {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 27px 0 $color_chicago_46_approx;
}
.edge-shadow-three {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 30px 40px -20px $color_tapa_approx;
}
.edge-shadow-four {
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 10px 40px -10px $color_tuatara_30_approx;
}
::placeholder {
  color: $white;
  opacity: 1;
}
:-ms-input-placeholder {
  color: $white;
}
::-ms-input-placeholder {
  color: $white;
}
.input {
  height: 67px;
  padding: 16px 60px;
  border: 0;
  font-size: 16px;
  font-family: Montserrat-Regular;
  width: 100%;
  color: $white;
  &:focus {
    border-color: $color_international_orange_approx;
  }
  &.bg-transparent::placeholder {
    color: $color_bright_gray_approx;
  }
  &.bg-transparent::-ms-input-placeholder {
    color: $color_bright_gray_approx;
  }
  &.bg-transparent:-ms-input-placeholder {
    color: $color_bright_gray_approx;
  }
}
.btn-abs {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 13px;
}
.has-ab-button {
  padding-right: 164px;
}
.invite-input {
  .input {
    height: 50px;
  }
  .btn-abs {
    top: 4px;
    right: 4px;
    bottom: 4px;
  }
}
.input-bordered {
  border: 1px solid $color_ghost_approx;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 30px;
}
.primary-bg {
  background-color: $color_trinidad_approx;
}
.primary-bg-ex {
  background-color: $color_trinidad_approx;
}
.secondary-bg {
  background-color: $color_black_haze_approx;
}
.bg-light-gray {
  background-color: $color_desert_storm_approx;
}
.bg-dark {
  background-color: $color_zeus_approx;
  color: $gray;
}
.bg-dark-su {
  background-color: $color_oxford_blue_approx;
}
.bg-transparent-white {
  background-color: $white_20;
}
.round-full {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
}
.defult-color {
  color: $color_shark_approx !important;
}
.secondary-color {
  color: $color_orange_approx !important;
}
.gray {
  color: $color_oslo_gray_approx !important;
  color: $color_bon_jour_approx !important;
}
.dark-gray {
  color: $color_bright_gray_approx;
}
.white {
  color: $white !important;
}
.gray-ex {
  color: $color_storm_dust_approx !important;
}
.transition-1 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.1s ease;
}
.transition-2 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s ease;
}
.transition-3 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease;
}
.transition-4 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease;
}
.transition-5 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.5s ease;
}
.transition-6 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.6s ease;
}
.transition-7 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.7s ease;
}
.transition-8 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.8s ease;
}
.transition-9 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.9s ease;
}
.transition-10 {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.1s ease;
}
.header {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.1s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 40px 0;
}
.responsive-nav-bar {
  width: 30px;
  height: 36px;
  background-color: transparent;
  border-color: transparent;
  border: 0;
  position: relative;
}
.nav-bar {
  width: 20px;
  height: 2px;
  background-color: $white;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%);
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease;
  &:after {
    @extend %extend_7;
    top: 6px;
  }
  &:before {
    @extend %extend_7;
    top: -6px;
  }
}
.isFixed {
  position: fixed;
  background-color: $black_70;
  padding: 15px 0;
}
.logo-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%);
}
.navigation {
  float: left;
  width: 50%;
}
.header-cta {
  float: left;
  width: 50%;
  a.btn {
    margin-left: 58px;
  }
}
.nav-item {
  display: inline-block;
  margin: 0 16px;
  a {
    padding: 5px;
  }
}
.hero-section {
  height: 100vh;
  min-height: 350px;
  padding-top: 122px;
}
.hero-form {
  input[type='search'] {
    font-family: Montserrat-Regular;
    font-size: 1.4rem;
  }
  input[type='submit'] {
    font-family: Montserrat-Regular;
  }
}
.article-block {
  padding-bottom: 300px;
}
.article-border {
  padding-bottom: 100px;
  border-bottom: 1px solid $wild_sand;
  margin-bottom: 90px;
}
.article-content {
  margin-top: 70px;
  .btn-gr {
    margin-top: 70px;
  }
}
.article-img {
  position: absolute;
  top: 0;
  width: calc(100% - (100% - (50% - 30px)));
  left: 0;
}
.article-mock {
  padding-bottom: 400px;
}
.carousel-wrap {
  margin-top: 50px;
  .slick-list {
    overflow: visible;
  }
}
.iphone-mock-top {
  @extend %extend_8;
  width: 450px;
  height: 99px;
  background-image: $url_11;
}
.iphone-mock-bottom {
  @extend %extend_8;
  width: 450px;
  height: 189px;
  background: $url_12;
}
.iphone-body {
  position: absolute;
  top: 0;
  left: calc(50% - -14.5px);
  width: 450px;
  background: $url_13 center repeat-y;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateX(-50%);
  height: 100%;
}
.screen-bg {
  width: 287px;
  height: 511px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: scale(0.9);
}
.download-button-gr {
  margin-top: 60px;
  a {
    margin-top: 10px;
  }
}
.review {
  padding: 60px 0 130px;
}
.avatar-img {
  padding: 70px 0;
  img {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.7);
    opacity: 0.5;
  }
  &.slick-current img {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1);
    opacity: 1;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 40px 40px 0 $color_mid_gray_28_approx;
  }
}
.avatar-carousel {
  .slick-arrow {
    width: 22px;
    height: 41px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px 41px;
  }
  .slick-prev {
    background-image: $url_14;
    left: 15px;
  }
  .slick-next {
    background-image: $url_15;
    right: 15px;
  }
}
.avatar-content-item {
  h3 {
    margin-bottom: 30px;
    &:before {
      content: '“';
    }
    &:after {
      content: '”';
    }
  }
  blockqoute {
    &:before {
      content: '“';
    }
    &:after {
      content: '”';
    }
  }
}
.review-icon {
  margin: 30px 0 60px;
}
.pb-details {
  padding-bottom: 0;
}
.details-topbar-top {
  padding: 34.5px 0;
}
.pd-details-main {
  padding: 95px 0 0;
}
.pd-details-block {
  padding-bottom: 170px;
  border-bottom: 1px solid $color_gallery_approx;
}
.price {
  font-size: 28px;
  line-height: normal;
  font-weight: 500;
  color: $color_trinidad_approx;
  margin: 20px 0 30px;
  display: block;
}
.check-list {
  margin-top: 20px;
  li {
    position: relative;
    margin: 0 0 20px;
    label {
      padding-left: 45px;
      margin: 0;
      line-height: 27px;
      font-weight: 500;
      &:after {
        border-style: solid;
        border-width: 1px;
        border-color: $color_silver_sand_approx;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 5px;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: 0 2px 5px 0 $black_15;
        width: 27px;
        height: 27px;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background-color: $white;
        background: none;
      }
      &:before {
        width: 27px;
        height: 27px;
        background: $url_16 center center no-repeat;
        content: '';
        position: absolute;
        background-size: 15px;
        left: 0;
        top: 0;
        z-index: 2;
        opacity: 0;
      }
    }
    input[type='checkbox'] {
      display: none;
      &:checked ~ label:before {
        opacity: 1;
      }
    }
  }
}
.pb-box {
  padding: 50px 0;
  [class*='col-'] {
    margin: 25px 0;
  }
}
.box-img {
  min-height: 212px;
  img {
    max-width: 350px;
    display: block;
    margin: 0 auto;
  }
}
.box-content {
  padding: 15px 15px 15px 25px;
  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
}
.quantity {
  width: 90px;
}
.quantity-num {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: Montserrat-Regular;
  text-align: center;
  border: 0;
  height: 42px;
  width: 100%;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 25px;
  background-color: transparent;
}
.quantity-nav {
  width: 17px;
  height: 17px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  font-weight: 500;
  text-align: center;
  color: $white;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateY(-50%);
}
.quantity-up {
  left: 0;
}
.quantity-down {
  right: 0;
}
.box-ab-content {
  .box-img {
    padding-bottom: 60px;
  }
  .box-img-item {
    padding-bottom: 60px;
    background-color: rgba(53, 147, 227, 0.5);
  }
  .box-content {
    padding: 20px 20px 40px 20px;
    position: relative;
    margin-top: -60px;
  }
  .plus-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
.plus-btn {
  width: 30px;
  height: 30px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  background-color: $color_trinidad_approx;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 7px 29px 0 $color_cod_gray_46_approx;
  color: $white;
  font-weight: 700;
  border: 0;
  padding: 0;
}
.hide {
  opacity: 0;
}
.fade-in {
  opacity: 0;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: opacity 0.8s linear;
  &.in-view {
    opacity: 1;
  }
}
.animate-from-bottom {
  opacity: 0;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateY(100px);
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate3d(0, 100px, 0);
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  &.in-view {
    opacity: 1;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(0px);
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate3d(0, 0, 0);
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  }
}
.animate-from-right {
  opacity: 0;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateY(50px);
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate3d(50px, 0, 0);
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  &.in-view {
    opacity: 1;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(0px);
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate3d(0, 0, 0);
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  }
}
.animate-from-left {
  opacity: 0;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translateY(-50px);
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate3d(-50px, 0, 0);
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  &.in-view {
    opacity: 1;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(0px);
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate3d(0, 0, 0);
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  }
}
@media only screen and(min-width: 320px) and(max-width: 767px) {
  input[type='text'] {
    appearance: none;
  }
  input[type='email'] {
    appearance: none;
  }
  input[type='tel'] {
    appearance: none;
  }
  input[type='search'] {
    appearance: none;
  }
  input[type='number'] {
    appearance: none;
  }
  input[type='submit'] {
    appearance: none;
  }
  input[type='password'] {
    appearance: none;
  }
  select {
    appearance: none;
  }
  textarea {
    appearance: none;
  }
  body {
    font-size: 16px;
    line-height: 20px;
  }
  h1 {
    font-size: 30px;
    br {
      display: none;
    }
  }
  h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    br {
      display: none;
    }
  }
  h3 {
    font-size: 23px;
    line-height: 30px;
  }
  .sub-title {
    font-size: 24px;
    line-height: 30px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .block {
    padding: 50px 0;
  }
  .btn-i-lg {
    width: 150px;
  }
  .header {
    padding: 0;
    background-color: $black_90;
    .container {
      padding: 0;
    }
  }
  .logo-wrap {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate(0, 0);
    padding: 10px 15px;
    float: left;
    .responsive-nav-bar {
      position: absolute;
      top: 50%;
      right: 15px;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateY(-50%);
    }
  }
  .main-logo {
    width: 150px;
    float: left;
  }
  .header-navigation {
    display: none;
    float: left;
    width: 100%;
    a {
      float: left;
      width: 100%;
      display: block;
      text-align: center;
      margin: 10px 0;
    }
  }
  .navigation {
    float: left;
    width: 100%;
  }
  .header-cta {
    float: left;
    width: 100%;
    display: block !important;
    a.btn {
      margin-left: 0;
    }
  }
  .nav-item {
    float: left;
    width: 100%;
    margin: 0;
    a {
      float: left;
      width: 100%;
      display: block;
      text-align: center;
      margin: 10px 0;
    }
  }
  .header-navigation-inner {
    float: left;
    width: 100%;
    padding: 25px 15px 50px;
    display: block !important;
  }
  .input {
    height: 50px;
    padding: 16px 20px;
  }
  .btn-abs {
    position: relative;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    width: 100%;
    margin-top: 15px;
  }
  .hero-section {
    min-height: 75vh;
    padding: 104px 0 50px;
  }
  .article-border {
    padding-bottom: 30px;
    margin-bottom: 30px;
    [class*='col-'] {
      margin: 15px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .pd-details-block {
    padding: 0 0 40px;
    [class*='col-'] {
      margin: 15px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .bg {
      min-height: 250px;
    }
  }
  .pb-box[class*='col-'] {
    margin: 15px 0;
  }
  .article-content {
    margin-top: 0;
    .btn-gr {
      margin-top: 20px;
    }
  }
  .article-block {
    padding-bottom: 0;
  }
  .carousel-wrap {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.8);
    margin: -80px 0;
  }
  .screen-bg {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1);
  }
  .review {
    padding: 0 0 70px;
  }
  blockqoute {
    font-size: 14px;
    line-height: 20px;
  }
  .review-icon {
    margin: 15px 0 30px;
  }
  .pb-details {
    padding-bottom: 0 !important;
  }
  .pd-details-main {
    padding-top: 30px;
  }
  .price {
    font-size: 28px;
    margin: 15px 0;
  }
  .check-list {
    margin-top: 10px;
    li {
      &:last-child {
        margin-bottom: 0;
      }
      label {
        padding-left: 37px;
      }
    }
  }
  .animate-from-left {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(100px);
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate3d(0, 100px, 0);
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  }
  .animate-from-right {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(100px);
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate3d(0, 100px, 0);
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s linear;
  }
  .btn-gr > .btn {
    margin: 6px;
  }
  input.btn.btn-lg[type='submit'] {
    padding: 13.5px 25px;
  }
  .invite-input .btn-abs {
    padding: 13.5px 20px;
  }
  .pb-box [class*='col-'] {
    margin: 15px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(-n + 3) {
      margin: 15px 0;
    }
    &:nth-last-of-type(-n + 3) {
      margin: 15px 0;
    }
  }
  .section-title h2 {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .avatar-carousel .slick-arrow {
    display: none !important;
  }
  .avatar-content-item h3 {
    margin-bottom: 15px;
  }
  .main-content .block:nth-child(1):not(.hero-section) {
    padding-top: 54px;
  }
  .details-topbar-top h4 span {
    margin-left: 5px;
  }
  .details-topbar-bottom li a {
    font-size: 16px;
    padding: 10px 15px;
  }
  .list-content p {
    font-size: 19px;
    line-height: 24px;
  }
}
@media only screen and(min-width: 320px) and(max-width: 375px) {
  .carousel-wrap {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.7);
  }
  .screen-bg {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.85);
  }
}
@media only screen and(min-width: 414px) and(max-width: 767px) {
  .pd-details-block [class*='col']:not(.list-content):last-child {
    margin-top: 0;
  }
}
@media only screen and(min-width: 480px) and(max-width: 767px) {
}
@media only screen and(min-width: 768px) and(max-width: 991px) {
  input[type='text'] {
    appearance: none;
  }
  input[type='email'] {
    appearance: none;
  }
  input[type='tel'] {
    appearance: none;
  }
  input[type='search'] {
    appearance: none;
  }
  input[type='number'] {
    appearance: none;
  }
  input[type='submit'] {
    appearance: none;
  }
  input[type='password'] {
    appearance: none;
  }
  select {
    appearance: none;
  }
  textarea {
    appearance: none;
  }
  h2 {
    font-size: 36px;
    line-height: 40px;
  }
  .btn-i-lg {
    width: 164px;
  }
  .block {
    padding: 100px 0;
  }
  .header {
    padding: 20px 0;
  }
  .nav-item {
    margin: 0 10px;
  }
  .article-border {
    padding-bottom: 80px;
    margin-bottom: 50px;
  }
  .article-block {
    padding-bottom: 0;
  }
  .article-content {
    margin-top: 0;
    .btn-gr {
      margin-top: 30px;
    }
  }
  .review {
    padding: 30px 0 100px;
  }
  .pb-details {
    padding-bottom: 0;
  }
  .details-topbar-top {
    padding: 10px 0;
  }
  .pd-details-main {
    padding-top: 50px;
  }
  .pd-details-block {
    padding-bottom: 80px;
    .bg {
      background-size: contain;
    }
    h3:not(.sub-title) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .price {
    font-size: 28px;
    margin: 10px 0;
  }
  .header-cta a.btn {
    margin-left: 30px;
  }
  .main-content .block:nth-child(1):not(.hero-section) {
    padding-top: 83px;
  }
  .details-topbar-bottom li a {
    padding: 10px 15px;
  }
  .list-content p {
    font-size: 19px;
    line-height: 24px;
  }
  .check-list li {
    margin-bottom: 15px;
    label {
      font-size: 14px;
      padding-left: 35px;
    }
  }
}
@media only screen and(min-width: 992px) and(max-width: 1024px) {
  input[type='text'] {
    appearance: none;
  }
  input[type='email'] {
    appearance: none;
  }
  input[type='tel'] {
    appearance: none;
  }
  input[type='search'] {
    appearance: none;
  }
  input[type='number'] {
    appearance: none;
  }
  input[type='submit'] {
    appearance: none;
  }
  input[type='password'] {
    appearance: none;
  }
  select {
    appearance: none;
  }
  textarea {
    appearance: none;
  }
  .article-block {
    padding-bottom: 0;
  }
  .hero-section br {
    display: none;
  }
}
@media only screen and(-webkit-min-device-pixel-ratio: 2),
  only screen and(min-device-pixel-ratio: 2) {
}
.clear:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  font-size: 0;
}
.bg-overlay > .bg:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease;
}
.bg-overlay-dark > .bg:after {
  background-color: $color_shark_approx;
}
.bg-overlay-blue > .bg:after {
  background-color: $color_curious_blue_approx;
}
.btn-icon-right img {
  margin-right: 10px;
}
.navOpen .nav-bar {
  background-color: transparent;
  &:before {
    top: 0;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(45deg);
  }
  &:after {
    top: 0;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(-45deg);
  }
}

.theme-black .header {
  background-color: $color_baltic_sea_approx;
}

.header-navigation a:not(.btn) {
  font-size: 18px;
  color: $white;
  &:hover {
    color: $color_international_orange_approx;
    text-decoration: none;
  }
}
.section-title h2 {
  padding-bottom: 35px;
  margin-bottom: 40px;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 2px;
    background-color: $color_trinidad_approx;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(-50%);
    content: '';
  }
}
.screen-item img {
  margin: 0 auto;
}
.slick-current .screen-bg {
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: scale(1);
}
.slick-slide:focus {
  outline: 0;
}
.slick-prev:before {
  display: none;
}
.slick-next:before {
  display: none;
}
.main-content .block:nth-child(1):not(.hero-section) {
  padding-top: 123px;
}
.details-topbar-bottom li {
  display: inline-block;
  a {
    padding: 16px 34px;
    font-size: 18px;
    line-height: 20px;
    color: $color_cod_gray_approx;
    font-weight: 500;
  }
  &:hover a {
    background-color: $color_gallery_approx;
    text-decoration: none;
  }
}
.list-content p {
  font-size: 19px;
  line-height: 30px;
  color: $color_storm_dust_approx;
  font-weight: 500;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 1500ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.menu-section-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  li {
    padding: 15px 0px;
    margin: 0px 6px;
    padding: 10px 8px;

    &:hover {
      background-color: #1e8b46;
      cursor: pointer;
      color: rgb(255, 255, 255);
    }
  }
}

.selected-section {
  background-color: #1e8b46;
  color: #fff;
}

.plus-btn:hover {
  background-color: #832a00;
}

.cart-icon {
  color: #fff;
  cursor: pointer;

  &:hover {
    color: $color_international_orange_approx;
  }
}

.food-item:hover {
  transform: translateY(-5px);
  transition: 0.3s;
}

.navmenu2 {
  display: none !important;
}

@media only screen and (max-width: 760px) {
  .navmenu2 {
    display: flex !important;
  }

  a {
    color: #fff;
  }

  footer a {
    color: #3593e3;
  }
}

// CSR

@import url('https://fonts.googleapis.com/css?family=Lobster');

#carousel {
  // position: absolute;
  height: 200px;
  width: 810px;
  margin: auto;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
}

.arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: #228291;
  line-height: 30px;
  margin-top: 85px;
  z-index: 1000;
}

.arrow-right {
  left: 780px;
}

.item {
  text-align: center;
  color: white;
  font-size: 40px;
  position: absolute;
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
}

$level2-height: 150px;
$level2-width: 110px;
$level1-height: 180px;
$level1-width: 130px;
$level0-height: 200px;
$level0-width: 150px;

$level-2-left: 650px;
$level-1-left: 500px;
$level0-left: 330px;
$level1-left: 180px;
$level2-left: 50px;

.level-2 {
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  background-color: #228291;
  left: $level-2-left;
  margin-top: 25px;
}

.level-1 {
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  background-color: #6796e5;
  left: $level-1-left;
  margin-top: 10px;
}

.level0 {
  height: $level0-height;
  width: $level0-width;
  line-height: $level0-height;
  background-color: #4ec9e1;
  left: $level0-left;
}

.level1 {
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  background-color: #6796e5;
  margin-top: 10px;
  left: $level1-left;
}

.level2 {
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  background-color: #228291;
  margin-top: 25px;
  left: $level2-left;
}

.left-enter {
  opacity: 0;
  left: $level2-left - $level2-width;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
}

.left-enter.left-enter-active {
  opacity: 1;
  left: $level2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 25px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.left-leave {
  opacity: 1;
  left: $level-2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 25px;
}

.left-leave.left-leave-active {
  left: $level-2-left + $level2-width + 20;
  opacity: 0;
  height: $level2-height - 30;
  line-height: 120px;
  margin-top: 40px;
  width: $level2-width - 20;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-enter {
  opacity: 0;
  left: $level-2-left + $level2-width;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
}

.right-enter.right-enter-active {
  left: $level-2-left;
  opacity: 1;
  height: $level2-height;
  margin-top: 25px;
  line-height: $level2-height;
  width: $level2-width;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-leave {
  left: $level2-left;
  height: $level2-height;
  opacity: 1;
  margin-top: 25px;
  line-height: $level2-height;
  width: $level2-width;
}

.right-leave.right-leave-active {
  left: $level2-left - $level2-width;
  opacity: 0;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 600px) {
  .hide-on-small-screens {
    display: none;
  }
}

.register {
  background-image: url('../assets/images/broadway.jpg');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  color: #fff;

  .logo {
    width: 300px;
    margin-bottom: 50px;
  }

  .content {
    max-width: 800px;

    h1 {
      color: #fff;
      font-size: 1.5rem;
      margin-bottom: 50px;
    }

    p {
      margin-bottom: 50px;
      font-size: 1rem;
    }

    img {
      width: 250px;
    }
  }
}

@media (max-width: 1200px) {
  #main-logo {
    display: none;
  }

  .get-app {
    display: flex;
    align-items: center;
    font-size: 0.6rem;

    img {
      width: 13px;
    }
  }
}

@media (max-width: 760px) {
  .header {
    border-bottom: 1px solid rgb(160, 160, 160);

    .logo-wrap {
      display: flex;
      align-items: center;

      .navmenu2 {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
  }

  .youtube-video {
    display: none;
  }

  .email-form {
    margin: 1.2rem !important;
  }
}

.cursor-hover {
  cursor: pointer;
}

.hover-dark:hover {
  color: rgb(158, 158, 158) !important;
  transition: 0.2s ease-in;
}

.hovergray:hover {
  background-color: rgb(244, 244, 244);
}

.selected-border-blue {
  border: 4px solid #73b3eb;
}

.selected-transparent-border {
  border: 4px solid transparent;
}

.faded-image {
  opacity: 0.55;
}

.faded-image:hover {
  opacity: 0.9;
  cursor: pointer;
}

.image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.lighten-hover:hover {
  color: rgb(191, 191, 191);
}
